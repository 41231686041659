import { useState } from "react";
import { LoginApi } from '../../../../helper/api'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";

function Illustration() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false)
  const [msg, setMsg] = useState("")

  const LoginSubmit = () => {
    LoginApi({
      username: username,
      password: password
    })
      .then(res => {
        const resData = res.data
        const data = resData.responses
        if (!resData.success) {
          setError(true)
          setMsg(resData.error)
        } else {
          localStorage.setItem('token', data.token);
          localStorage.setItem('username', data.username);
          localStorage.setItem('role', data.role);
          window.location.href = '/dashboards';
        }
      })
      .catch(err => {
        let data = err.response.data
        console.log(err.response)
        if (!data.success) {
          setError(true)
          setMsg(data.error)
        }
      })
  }

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your Username and password to sign in"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput type="username" label="Username" fullWidth onChange={event => setUsername(event.target.value)} />
        </MDBox>
        <MDBox mb={2}>
          <MDInput type="password" label="Password" fullWidth onChange={event => setPassword(event.target.value)} />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton disabled={username == "" || password.length < 8} variant="gradient" color="info" size="large" fullWidth onClick={() => LoginSubmit()}>
            sign in
          </MDButton>
        </MDBox>
        {error ? <MDAlert color="error" dismissible>{msg}</MDAlert> : null}
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
