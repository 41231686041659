import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import _ from "lodash";

import {
  GetReceiptById,
  NewReceipt,
  EditReceipt,
  AllCompany,
} from "../../../helper/api";

export default function Edit_Receipt() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [c_id, setC_id] = useState("1");
  const [drayage_id, setDrayage_id] = useState("1");
  const [container_no, setContainer_no] = useState("");
  const [size, setSize] = useState("20");
  const [bill, setBill] = useState("");
  const [seal_no, setSeal_no] = useState("");
  const [vessel, setVessel] = useState("");
  const [import_var, setImport] = useState(1);
  const [pick_up, setPick_up] = useState("");
  const [return_to, setReturn_to] = useState("");
  const [tractor, setTractor] = useState("");
  const [trailer, setTrailer] = useState("");
  const [tunnel_fee, setTunnel_fee] = useState("");
  const [remark, setRemark] = useState("");
  const [reporter, setReporter] = useState("");

  const [company, setCompany] = useState([]);

  const Submit = () => {
    let data = {
      c_id: c_id.id,
      drayage_id: drayage_id.id,
      container_no: container_no,
      size: size,
      bill: bill,
      seal_no: seal_no,
      vessel: vessel,
      import: import_var.value,
      pick_up: pick_up,
      return_to: return_to,
      tractor: tractor,
      trailer: trailer,
      tunnel_fee: tunnel_fee,
      remark: remark,
      reporter: reporter,
    };

    console.log(data);
    if (id === undefined) {
      NewReceipt(data)
        .then((res) => {
          if (res.data.success) navigate("/admin/receipt");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      EditReceipt(id, data)
        .then((res) => {
          if (res.data.success) navigate("/admin/receipt");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (company.length == 0) {
        AllCompany()
          .then((res) => {
            setCompany(res.data.responses);
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 403) console.log(err);
          });

    }

    if (id !== undefined && company.length > 0) {
      GetReceiptById(id)
        .then((res) => {
          let d = res.data.responses;
          console.log(d);
          setC_id(_.find(company, ['id', d.c_id]));
          setDrayage_id(_.find(company, ['id', d.drayage_id]));
          setContainer_no(d.container_no);
          setSize(d.size);
          setBill(d.bill);
          setSeal_no(d.seal_no);
          setVessel(d.vessel);
          setImport(_.find([
            { title: "入口", value: 1 },
            { title: "出口", value: 2 },
          ], ['value', d.import]));
          setPick_up(d.pick_up);
          setReturn_to(d.return_to);
          setTractor(d.tractor);
          setTrailer(d.trailer);
          setTunnel_fee(d.tunnel_fee);
          setRemark(d.remark);
          setReporter(d.reporter);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [company]);

  const checkSubmit = () => {
    return false;
  };

  return (
    <MDBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={2} />
        <Grid item xs={12} lg={9}>
          <Card sx={{ overflow: "visible" }}>
            <MDBox p={3}>
              <MDTypography variant="h5">
                {id === undefined ? <h1> 新增拖運單 </h1> : <h1> 更改拖運單 </h1>}
              </MDTypography>
              <Link to="/admin/receipt" style={{ float: "right" }}>
                <MDButton color="primary">返回</MDButton>
              </Link>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={c_id}
                    onChange={(_, value) => setC_id(value)}
                    getOptionSelected={(option) => option.id == c_id}
                    options={company}
                    getOptionLabel={(option) => option.c_name}
                    renderInput={(params) => (
                      <FormField size="large" {...params} label="公司名稱" InputLabelProps={{ shrink: true }} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={drayage_id}
                    onChange={(_, value) => setDrayage_id(value)}
                    getOptionSelected={(option) => option.id == drayage_id}
                    options={company}
                    getOptionLabel={(option) => option.c_name}
                    renderInput={(params) => (
                      <FormField size="large" {...params} label="拖運地點" InputLabelProps={{ shrink: true }} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={container_no}
                    label="貨櫃號碼"
                    placeholder="輸入貨櫃號碼"
                    onChange={(event) => setContainer_no(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    onChange={(_, value) => setSize(value)}
                    value={size || 0}
                    options={["20", "40", "40H", "45", "45H"]}
                    renderInput={(params) => (
                      <FormField size="large" {...params} label="呎碼" InputLabelProps={{ shrink: true }} />
                    )} 
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={bill}
                    label="提單或落貨紙"
                    placeholder="輸入提單或落貨紙"
                    onChange={(event) => setBill(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={seal_no}
                    label="封條號碼"
                    placeholder="輸入封條號碼"
                    onChange={(event) => setSeal_no(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={vessel}
                    label="船名 / 航次"
                    placeholder="輸入船名 / 航次"
                    onChange={(event) => setVessel(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    getOptionSelected={(option, value) => option.value == value}
                    onChange={(_, value) => {
                      setImport(value);
                    }}
                    value={import_var}
                    getOptionLabel={(option) => option.title}
                    options={[
                      { title: "入口", value: 1 },
                      { title: "出口", value: 2 },
                    ]}
                    renderInput={(params) => (
                      <FormField size="large" {...params} label="入 / 出口" InputLabelProps={{ shrink: true }} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={pick_up}
                    label="取櫃地點"
                    placeholder="輸入取櫃地點"
                    onChange={(event) => setPick_up(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={return_to}
                    label="交櫃地點"
                    placeholder="輸入交櫃地點"
                    onChange={(event) => setReturn_to(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={tractor}
                    label="車頭號碼"
                    placeholder="輸入車頭號碼"
                    onChange={(event) => setTractor(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    size="large"
                    value={trailer}
                    label="車架號碼"
                    placeholder="輸入車架號碼"
                    onChange={(event) => setTrailer(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    size="large"
                    value={tunnel_fee}
                    label="隧道費"
                    placeholder="輸入隧道費"
                    onChange={(event) => setTunnel_fee(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    size="large"
                    value={remark}
                    label="Remarks"
                    placeholder="輸入Remarks"
                    onChange={(event) => setRemark(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    size="large"
                    value={reporter}
                    label="報關員"
                    placeholder="輸入報關員"
                    onChange={(event) => setReporter(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <MDButton
                        disabled={checkSubmit()}
                        variant="gradient"
                        color="info"
                        size="large"
                        fullWidth
                        style={{ float: "right" }}
                        onClick={() => Submit()}
                    >
                        提交
                    </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
