import React, { useState } from "react";
import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import f from "assets/fonts/cwTeXKai.ttf";
import fb from "assets/fonts/500sf.ttf";
import officeIcon from "assets/images/sunwo.png";
import moment from "moment";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "Text",
    fontFamily: "cwTeXKai",
    marginLeft: "15mm",
    marginRight: "15mm",
    paddingTop: "10mm",
    paddingBottom: "10mm",
    fontSize: 14,
  },
  section: {
    fontSize: 12,
    fontFamily: "cwTeXKai",
    margin: "2mm",
    padding: "2mm",
    flexGText: 1,
  },
  header: {
    fontSize: 18,
    fontFamily: "cwTeXKai",
    fontWeight: 700,
  },
  cellEmpty: {
    borderTop: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "1.2cm",
  },
  cell: {
    border: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "1.2cm",
  },
  cellTop: {
    borderLeft: "0.5mm",
    borderRight: "0.5mm",
    borderTop: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    paddingTop: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  cellBottom: {
    borderLeft: "0.5mm",
    borderRight: "0.5mm",
    borderBottom: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    paddingBottom: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  cellMiddle: {
    borderLeft: "0.5mm",
    borderRight: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    paddingTop: "2mm",
    paddingBottom: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "6mm",
  },
  back: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "cwTeXKai",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "18cm",
    fontFamily: "cwTeXKai",
  },
});

Font.register({
  family: "Noto Serif TC",
  fonts: [{ src: fb }],
});
Font.register({
  family: "cwTeXKai",
  fonts: [{ src: f }],
});
// Create Document Component
export default function MyDocument(props) {
  const [total] = useState(props.total);
  const [data] = useState(props.data);

  const [company] = useState(props.company);


  function createEmpty() {
    let a = [];
    for (let x = 0; x < 20 - data.length; x++) {
      a.push(
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellMiddle, { width: "30%" }]}></Text>
          <Text style={[styles.cellMiddle, { width: "20%" }]}></Text>
          <Text style={[styles.cellMiddle, { width: "30%" }]}></Text>
          <Text style={[styles.cellMiddle, { width: "20%" }]}></Text>
        </View>
      );
    }
    return a;
  }

  function ToName(id) {
    for (var i = 0; i < company.length; i++) {
      if (company[i].id == id) {
        return "         " + company[i].c_name;
      }
    }
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image
          style={{
            left: "7mm",
            marginTop: "22mm",
            marginBottom: "2mm",
            position: "absolute",
            width: "10%",
          }}
          src={officeIcon}
        />
        <View style={[styles.row, { marginTop: "10mm" }]}>
          <Text style={[styles.header, { width: "100%", fontSize: 24, textAlign: "center" }]}>
            新和貨櫃有限公司
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.header,
              { width: "100%", fontSize: 18, textAlign: "center", fontFamily: "Noto Serif TC" },
            ]}
          >
            SUN WO CONTAINER COMPANY LTD.
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={{ textAlign: "center", fontSize: 16, width: "100%", marginTop: "1mm" }}>
            元朗福喜街福喜停車場
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              width: "100%",
              fontFamily: "Noto Serif TC",
            }}
          >
            Tel : 24647450 Fax: 24634541
          </Text>
        </View>
        <View style={[styles.row, { marginBottom: "5mm" }]} wrap={false}>
          <Text
            style={{
              textAlign: "right",
              width: "100%",
              paddingRight: "3mm",
              fontFamily: "Noto Serif TC",
            }}
          >
            Date : {moment(new Date()).format("yyyy/MM/DD")}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[{ fontSize: 16, width: "100%", textAlign: "left", height: "14mm" }]}>
            {props.year + " 年 " + props.month + " 月" }
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[{ fontSize: 20, width: "23mm", textAlign: "left", height: "14mm" }]}>
            {"客戶： "}
          </Text>
          <Text
            style={[
              {
                fontSize: 20,
                textAlign: "left",
                marginRight: "1.5mm",
                height: "14mm",
              },
            ]}
          >
            {data[0]["c_id"] == 0 ? "全部" : ToName(data[0]["c_id"])}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellTop, { width: "30%" }]}>發票編號</Text>
          <Text style={[styles.cellTop, { width: "20%" }]}>日期</Text>
          <Text style={[styles.cellTop, { width: "30%" }]}>貨名</Text>
          <Text style={[styles.cellTop, { width: "20%" }]}>金額</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellBottom, { fontSize: 11, width: "30%", fontFamily: "Noto Serif TC" }]}>INVOICE Number</Text>
          <Text style={[styles.cellBottom, { fontSize: 11, width: "20%", fontFamily: "Noto Serif TC" }]}>Date</Text>
          <Text style={[styles.cellBottom, { fontSize: 11, width: "30%", fontFamily: "Noto Serif TC" }]}>DESPRIPTION</Text>
          <Text style={[styles.cellBottom, { fontSize: 11, width: "20%", fontFamily: "Noto Serif TC" }]}>AMOUNT(HKD)</Text>
        </View>
        {data.map((item, i) => (
          <View style={styles.row} wrap={false} key={i}>
            <Text style={[styles.cellMiddle, { width: "30%" }]}>
              {item.despription_number == 1 ? item.invoice_id : null}
            </Text>
            <Text style={[styles.cellMiddle, { width: "20%" }]}>
              {item.despription_number == 1 ? item.date_add : null}
            </Text>
            <Text style={[styles.cellMiddle, { width: "30%" }]}>{item.despription}</Text>
            <Text style={[styles.cellMiddle, { width: "20%" }]}>
              {numberWithCommas(Number.parseFloat(item.price).toFixed(2))}
            </Text>
          </View>
        ))}
        {createEmpty()}
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellEmpty, { width: "30%" }]}></Text>
          <Text style={[styles.cellEmpty, { width: "20%" }]}></Text>
          <Text style={[styles.cell, { width: "30%", fontFamily: "Noto Serif TC" }]}>{"合計\nTOTAL HKD:"}</Text>
          <Text style={[styles.cell, { paddingTop: "6mm", alignItems: "center", fontSize: 16, width: "20%" }]}>{numberWithCommas(Number.parseFloat(total).toFixed(2))}</Text>
        </View>
      </Page>
    </Document>
  );
}

// Setting default props for the Header
MyDocument.defaultProps = {
  data: [],
  year: "",
  month: "",
  company: [],
  total: 0,
};

// Typechecking props for the Header
MyDocument.propTypes = {
  data: PropTypes.array,
  company: PropTypes.array,
  year: PropTypes.string,
  month: PropTypes.string,
  total: PropTypes.number,
};
