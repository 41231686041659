import axios from "axios";
// const admin_URL = "https://api.sunwo.xyz/"
const admin_URL = window.API_URL !== undefined ? window.API_URL + "api/v1/"  : "http://192.168.1.10:8080/api/v1/"
const UserURL = admin_URL + "users/";
const ReceiptURL =  admin_URL + "receipts/";
const InvoiceURL =  admin_URL + "invoices/";
const CompanyURL =  admin_URL + "companies/";
const DespriptionURL =  admin_URL + "despriptions/";
const TokenURL =  admin_URL;
const TOKEN =  localStorage.getItem('token')

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        // window.location.href = "/authentication/login"
      }
      return Promise.reject(error.response);
  }
);

const UserRequest = axios.create({
  baseURL: UserURL
});

const ReceiptRequest = axios.create({
  baseURL: ReceiptURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Authorization': 'Bearer ' +  TOKEN
  }
});

const InvoiceRequest = axios.create({
  baseURL: InvoiceURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Authorization': 'Bearer ' +  TOKEN
  }
});

const CompanyRequest = axios.create({
  baseURL: CompanyURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Authorization': 'Bearer ' +  TOKEN
  }
});

const TokenRequest = axios.create({
  baseURL: TokenURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Authorization': 'Bearer ' +  TOKEN
  }
});

const DespriptionRequest = axios.create({
  baseURL: DespriptionURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Authorization': 'Bearer ' +  TOKEN
  }
});



export const TokenCheck = () => TokenRequest.get('/');

export const LoginApi =  data => UserRequest.post('/login', data);
export const SignUpApi =  data => UserRequest.post('/signup', data);

export const AllReceipt =  () => ReceiptRequest.get('/');
export const GetReceiptById = id  => ReceiptRequest.get('/' + id);
export const EditReceipt = (id, data)  => ReceiptRequest.post('/' + id, data);
export const NewReceipt = data  => ReceiptRequest.post('/', data );

export const AllInvoice =  () => InvoiceRequest.get('/');
export const GetInvoiceById = id  => InvoiceRequest.get('/' + id);
export const EditInvoice = (id, data)  => InvoiceRequest.post('/' + id, data);
export const NewInvoice = data  => InvoiceRequest.post('/', data );
export const InvoiceGetCompany = (c_id, year, month)  => InvoiceRequest.get('/monthly/'+c_id+'/'+year+'/'+month );

export const AllCompany = ()  => CompanyRequest.get('/');
export const GetCompanyById = id  => CompanyRequest.get('/' + id);
export const EditCompany = (id, data)  => CompanyRequest.post('/' + id, data);
export const NewCompany = data  => CompanyRequest.post('/', data );

export const AllDespription = ()  => DespriptionRequest.get('/');
export const GetDespriptionById = id  => DespriptionRequest.get('/' + id);
export const EditDespription = (id, data)  => DespriptionRequest.post('/' + id, data);
export const NewDespription = data  => DespriptionRequest.post('/', data );
// export const ReadThumbnailReceipt =  path => ImageRequest.get('/thumbnail/' + path, {
//   responseType: 'arraybuffer' 
// });