import React from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
// @material-ui core components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import Doc from "./pdf";
import { isMobile } from "react-device-detect";

// Create Document Component
export default function MyDocument() {
  let { id } = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            {isMobile ? (
              <PDFDownloadLink document={<Doc id={id} />} fileName={`receipt_${id}.pdf`}>
                {({ loading }) => (loading ? "正在生成PDF..." : "按此下載PDF")}
              </PDFDownloadLink>
            ) : null}
            <PDFViewer style={{ width: "100%", height: "85vh" }}>
              <Doc id={id} />
            </PDFViewer>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
