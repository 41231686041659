import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Form from "./Form";
import { typeArr } from "./config"
// Data
import { AllDespription } from "../../../helper/api";
import _ from "lodash";
function DataTables() {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(false);
  const [show, setShow] = useState(false);

  const toggleSnackbar = () => setShow(false);

  const [despription, setDespription] = useState([]);

  const handleClickOpen = (val) => {
    setItem(val);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccessClose = () => {
    setShow(true);
    setOpen(false);
    getDescription()
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "貨品",
      accessor: "despription",
    },
    {
      Header: "類別",
      accessor: "type",
      Cell: ({ value }) => typeArr[value],
    },
    {
      Header: "Edit",
      accessor: "edit",
      align: "center",
    },
  ];

  function edit(item) {
    return (
      <MDButton color="info" onClick={() => handleClickOpen(item)}>
        更改
      </MDButton>
    );
  }

  const getDescription = () => {
    AllDespription()
        .then((res) => {
          setDespription(res.data.responses);
        })
        .catch((err) => {
          console.log(err);
        })
  }
  useEffect(() => {
    if (despription.length > 0) {
      var tmp = [];

      despription.forEach((item) => {
        item.edit = edit(item);
        tmp.push(item);
      });

      setData(_.orderBy(tmp, ["id"], ["desc"]));
    }
  }, [despription]);

  useEffect(() => {
    if (despription.length == 0) {
      getDescription()
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              貨品
            </MDTypography>
            <MDTypography variant="button" color="Header">
              Despription
            </MDTypography>
            <MDButton
              style={{ float: "right" }}
              color="primary"
              onClick={() => handleClickOpen({ id: 0, despription: "" })}
            >
              新增貨品
            </MDButton>
          </MDBox>
          <DataTable table={{ rows: data, columns }} canSearch />
        </Card>
      </MDBox>
      <Dialog onClose={handleClose} open={open} maxWidth="lg" fullWidth>
        {open ? <Form item={item} handleClose={handleSuccessClose} /> : null}
      </Dialog>
      <MDSnackbar
        color="success"
        icon="notifications"
        title="更新"
        content="更新成功"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={show}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;
