import React from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui core components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Doc from "./pdf";
import { isMobile } from "react-device-detect";

// Create Document Component
export default function MyDocument(props) {
  let { id } = useParams();

  return (
    <MDBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          {isMobile ? (
            <PDFDownloadLink
              document={
                <Doc
                  data={props.data}
                  company={props.company}
                  month={props.month}
                  year={props.year}
                  total={props.total}
                />
              }
              fileName={`receipt_${id}.pdf`}
            >
              {({ loading }) => (loading ? "正在生成PDF..." : "按此下載PDF")}
            </PDFDownloadLink>
          ) : null}
          <PDFViewer style={{ width: "100%", height: "65vh" }}>
            <Doc
              month={props.month}
              year={props.year}
              data={props.data}
              company={props.company}
              total={props.total}
            />
          </PDFViewer>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Setting default props for the Header
MyDocument.defaultProps = {
  data: [],
  company: [],
  year: "",
  month: "",
  total: 0,
};

// Typechecking props for the Header
MyDocument.propTypes = {
  data: PropTypes.array,
  company: PropTypes.array,
  year: PropTypes.string,
  month: PropTypes.string,
  total: PropTypes.number,
};
