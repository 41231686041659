import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import _ from "lodash";

import {
  GetInvoiceById,
  AllDespription,
  NewInvoice,
  EditInvoice,
  AllCompany,
} from "../../../helper/api";

import moment from "moment";
export default function Edit_Receipt() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [c_id, setC_id] = useState("1");
  const [container_no, setContainer_no] = useState("");
  const [size, setSize] = useState("20");
  const [car_number, setCar_number] = useState("");
  const [custom_number, setCustom_number] = useState("");
  const [date_add, setDateAdd] = useState(new Date());

  const [company, setCompany] = useState([]);
  const [all_despription, setAllDespription] = useState([]);

  const [inputList, setInputList] = useState([{ price: "", despription: "" }]);

  const Submit = () => {
    let data = {
      c_id: c_id.id,
      container_number: container_no,
      size: size,
      car_number: car_number,
      custom_number: custom_number,
      detail: inputList.map((value) => {
        return { ...value, price: parseFloat(value.price) };
      }),
      date_add: moment(date_add).format("yyyy/MM/DD"),
    };

    console.log(data);
    if (id === undefined) {
      NewInvoice(data)
        .then((res) => {
          if (res.data.success) navigate("/admin/invoice");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      EditInvoice(id, data)
        .then((res) => {
          if (res.data.success) navigate("/admin/invoice");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (company.length == 0) {
      AllCompany()
        .then((res) => {
          setCompany(res.data.responses);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 403) console.log(err);
        });
    }
    if (all_despription.length == 0) {
      AllDespription()
        .then((res) => {
          setAllDespription(res.data.responses);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [company]);

  useEffect(() => {
    if (id !== undefined && company.length > 0 && all_despription.length > 0) {
      GetInvoiceById(id)
        .then((res) => {
          let d = res.data.responses;
          setC_id(_.find(company, ["id", d[0].c_id]));
          setContainer_no(d[0].container_number);
          setSize(d[0].size);
          setCar_number(d[0].car_number);
          setCustom_number(d[0].custom_number);
          setDateAdd(d[0].date_add);
          let detail = [];
          d.map((item) => {
            detail.push({
              price: item.price,
              despription: _.find(all_despription, ["despription", item.despription]),
            });
          });
          setInputList(detail);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [company, all_despription]);

  const checkSubmit = () => {
    for (var x = 0; x < inputList.length; x++) {
      if (inputList[x].despription == "" || inputList[x].price == "") return true;
    }
    return false;
  };

  // handle input change
  const handleInputChange = (value, index) => {
    const list = [...inputList];
    list[index]["despription"] = value;
    setInputList(list);
  };

  const handlePriceChange = (index, value) => {
    const list = [...inputList];
    list[index]["price"] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = (price, despription) => {
    setInputList([...inputList, { price: price, despription: despription }]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} style={{ marginBottom: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">
                  {id === undefined ? <h1> 新增發票 </h1> : <h1> 更改發票 </h1>}
                </MDTypography>
                <Link to="/admin/invoice" style={{ float: "right" }}>
                  <MDButton color="primary">返回</MDButton>
                </Link>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      value={c_id}
                      onChange={(_, value) => setC_id(value)}
                      getOptionSelected={(option) => option.id == c_id}
                      options={company}
                      getOptionLabel={(option) => option.c_name}
                      renderInput={(params) => (
                        <FormField
                          size="large"
                          {...params}
                          label="公司名稱"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Autocomplete
                      onChange={(_, value) => setSize(value)}
                      value={size || 0}
                      options={["20", "40", "45"]}
                      renderInput={(params) => (
                        <FormField
                          size="large"
                          {...params}
                          label="呎碼"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <MDDatePicker
                      onChange={([date]) => {
                        setDateAdd(date);
                      }}
                      value={date_add}
                      options={{ maxDate: new Date(), dateFormat: "Y/m/d" }}
                      input={{ placeholder: "日期" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      size="large"
                      value={container_no}
                      label="貨櫃號碼"
                      placeholder="輸入貨櫃號碼"
                      onChange={(event) => setContainer_no(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      size="large"
                      value={car_number}
                      label="車牌號碼"
                      placeholder="輸入車牌號碼"
                      onChange={(event) => setCar_number(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      size="large"
                      value={custom_number}
                      label="海關號碼"
                      placeholder="輸入海關號碼"
                      onChange={(event) => setCustom_number(event.target.value)}
                    />
                  </Grid>
                  {inputList.map((v, i) => {
                    return (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            value={v["despription"]}
                            onChange={(_, value) => handleInputChange(value, i)}
                            getOptionSelected={(option) => option.id == c_id}
                            options={all_despription}
                            getOptionLabel={(option) => option.despription}
                            renderInput={(params) => (
                              <FormField
                                size="large"
                                {...params}
                                label="貨品名稱"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <FormField
                            size="large"
                            value={v["price"]}
                            label="金額"
                            placeholder="輸入金額"
                            onChange={(event) => handlePriceChange(i, event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                          <MDButton
                            disabled={inputList.length === 1}
                            variant="gradient"
                            color="error"
                            size="large"
                            style={{ float: "right" }}
                            onClick={() => handleRemoveClick(i)}
                          >
                            刪除
                          </MDButton>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item xs={12} sm={12} style={{ float: "right" }}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="large"
                      style={{ float: "right" }}
                      onClick={() => handleAddClick("", "")}
                    >
                      新增
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <MDButton
                      disabled={checkSubmit()}
                      variant="gradient"
                      color="info"
                      size="large"
                      fullWidth
                      style={{ float: "right" }}
                      onClick={() => Submit()}
                    >
                      提交
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
