import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import { GetReceiptById, AllCompany } from "../../../helper/api";
import f from "assets/fonts/500sf.ttf";
import fb from "assets/fonts/700sf.ttf";
import officeIcon from "assets/images/sunwo.png";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "Text",
    fontFamily: "Noto Serif TC",
    margin: 5,
    padding: 10,
    fontSize: 12,
  },
  section: {
    fontSize: 12,
    fontFamily: "Noto Serif TC",
    margin: "2mm",
    padding: "2mm",
    flexGText: 1,
  },
  header: {
    fontSize: 18,
    fontFamily: "Noto Serif TC",
  },
  cell: {
    border: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "1.2cm",
  },
  cellTop: {
    borderLeft: "0.5mm",
    borderRight: "0.5mm",
    borderTop: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    paddingTop: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  cellBottom: {
    borderLeft: "0.5mm",
    borderRight: "0.5mm",
    borderBottom: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    paddingBottom: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  cellMiddle: {
    borderLeft: "0.5mm",
    borderRight: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "1.2cm",
  },
  back: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Noto Serif TC",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "20cm",
    marginLeft: "2mm",
    marginRight: "4mm",
  },
});

Font.register({
  family: "Noto Serif TC",
  fonts: [
    {
      src: f,
    },
    { src: fb, fontStyle: "bold", fontWeight: 700 },
  ],
});

// Create Document Component
export default function MyDocument(props) {
  const [id] = useState(props.id);
  const [c_id, setC_id] = useState("1");
  const [drayage_id, setDrayage_id] = useState("1");
  const [container_no, setContainer_no] = useState("");
  const [size, setSize] = useState("20");
  const [bill, setBill] = useState("");
  const [seal_no, setSeal_no] = useState("");
  const [vessel, setVessel] = useState("");
  const [import_var, setImport] = useState(1);
  const [pick_up, setPick_up] = useState("");
  const [return_to, setReturn_to] = useState("");
  const [tractor, setTractor] = useState("");
  const [trailer, setTrailer] = useState("");
  const [tunnel_fee, setTunnel_fee] = useState("");
  const [remark, setRemark] = useState("");
  const [reporter, setReporter] = useState("");
  const [date_modify, setDateModify] = useState("");

  const [company, setCompany] = useState([]);

  useEffect(() => {
    if (company.length == 0) {
      AllCompany()
        .then((res) => {
          setCompany(res.data.responses);
        })
        .catch((err) => console.log(err));
    }
    if (id !== undefined && company.length > 0) {
      GetReceiptById(id)
        .then((res) => {
          let data = res.data.responses;
          setC_id(data.c_id);
          setDrayage_id(data.drayage_id);
          setContainer_no(data.container_no);
          setSize(data.size);
          setBill(data.bill);
          setSeal_no(data.seal_no);
          setVessel(data.vessel);
          setImport(data.import);
          setPick_up(data.pick_up);
          setReturn_to(data.return_to);
          setTractor(data.tractor);
          setTrailer(data.trailer);
          setTunnel_fee(data.tunnel_fee);
          setRemark(data.remark);
          setReporter(data.reporter);
          setDateModify(data.date_modify);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [company]);

  function ToName(id) {
    for (var i = 0; i < company.length; i++) {
      if (company[i].id == id) {
        return (
          company[i].c_name +
          "  " +
          company[i].phone +
          "  " +
          company[i].contact +
          "  " +
          company[i].phone2 +
          "\n" +
          company[i].c_address
        );
      }
    }
  }

  function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image
          style={{
            left: "20mm",
            marginTop: "25mm",
            marginBottom: "2mm",
            position: "absolute",
            width: "10%",
          }}
          src={officeIcon}
        />
        <View style={[styles.row, { marginTop: "20mm" }]}>
          <Text style={[styles.header, { width: "100%", fontSize: 18, textAlign: "center", fontWeight: "bold" }]}>
            新和貨櫃有限公司
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.header, { width: "100%", fontSize: 18, textAlign: "center", fontWeight: "bold" }]}>
            SUN WO CONTAINER COMPANY LTD.
          </Text>
        </View>
        <Text style={{ textAlign: "center", fontSize: 14 }}>元朗福喜街福喜停車場</Text>
        <Text style={{ textAlign: "center", fontSize: 14 }}>Tel : 24647450 Fax: 24634541</Text>
        <View style={[styles.row, { marginTop: "10mm", marginBottom: "2mm" }]} wrap={false}>
          <Text style={{ textAlign: "right", width: "65%", marginRight: "35mm" }}>拖運收據</Text>
          <Text style={{ textAlign: "right", width: "35%", marginRight: "3mm" }}>
            No : {pad(id, 5)}
          </Text>
        </View>
        <View style={[styles.row, { marginBottom: "10mm" }]} wrap={false}>
          <Text style={{ textAlign: "right", width: "65%", marginRight: "16mm" }}>
            DRAYAGE RECEIPT
          </Text>
          <Text style={{ textAlign: "right", width: "35%", marginRight: "3mm" }}>
            Date : {date_modify}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text
            style={[
              styles.cell,
              { width: "100%", textAlign: "left", marginRight: "1.5mm", height: "1.75cm" },
            ]}
          >
            {"託運公司 By Order of : " + ToName(c_id)}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text
            style={[
              styles.cell,
              { width: "100%", textAlign: "left", marginRight: "1.5mm", height: "1.75cm" },
            ]}
          >
            {"拖運地點 Drayage Address : " + ToName(drayage_id)}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellTop, { width: "35%" }]}>Container No.</Text>
          <Text style={[styles.cellTop, { width: "10%" }]}>Size</Text>
          <Text style={[styles.cellTop, { width: "35%" }]}>Bill of Lading / Shipping Order</Text>
          <Text style={[styles.cellTop, { width: "20%" }]}>Seal No.</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellBottom, { width: "35%" }]}>貨櫃號碼</Text>
          <Text style={[styles.cellBottom, { width: "10%" }]}>呎碼</Text>
          <Text style={[styles.cellBottom, { width: "35%" }]}>提單或落貨紙</Text>
          <Text style={[styles.cellBottom, { width: "20%" }]}>封條號碼</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cell, { width: "35%" }]}>{container_no}</Text>
          <Text style={[styles.cell, { width: "10%" }]}>{size}</Text>
          <Text style={[styles.cell, { width: "35%" }]}>{bill}</Text>
          <Text style={[styles.cell, { width: "20%" }]}>{seal_no}</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellTop, { width: "35%" }]}>Vessel / Voyage</Text>
          <Text style={[styles.cellTop, { width: "23%" }]}>Import / Export</Text>
          <Text style={[styles.cellTop, { width: "22%" }]}>Pick up From</Text>
          <Text style={[styles.cellTop, { width: "20%" }]}>Return To</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellBottom, { width: "35%" }]}>船名 / 航次</Text>
          <Text style={[styles.cellBottom, { width: "23%" }]}>入 / 出口</Text>
          <Text style={[styles.cellBottom, { width: "22%" }]}>取櫃地點</Text>
          <Text style={[styles.cellBottom, { width: "20%" }]}>交櫃地點</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cell, { width: "35%" }]}>{vessel}</Text>
          <Text style={[styles.cell, { width: "23%" }]}>{import_var == 1 ? "入口" : "出口"}</Text>
          <Text style={[styles.cell, { width: "22%" }]}>{pick_up}</Text>
          <Text style={[styles.cell, { width: "20%" }]}>{return_to}</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellTop, { width: "25%" }]}>Driver Signature</Text>
          <Text style={[styles.cellTop, { width: "25%" }]}>Tractor No.</Text>
          <Text style={[styles.cellTop, { width: "25%" }]}>Trailer No.</Text>
          <Text style={[styles.cellTop, { width: "25%" }]}>Tunnel Fee</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellBottom, { width: "25%" }]}>司機簽收</Text>
          <Text style={[styles.cellBottom, { width: "25%" }]}>車架號碼</Text>
          <Text style={[styles.cellBottom, { width: "25%" }]}>車架號碼</Text>
          <Text style={[styles.cellBottom, { width: "25%" }]}>隧道費</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cell, { width: "25%" }]}></Text>
          <Text style={[styles.cell, { width: "25%" }]}>{tractor}</Text>
          <Text style={[styles.cell, { width: "25%" }]}>{trailer}</Text>
          <Text style={[styles.cell, { width: "25%" }]}>{tunnel_fee}</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text
            style={[
              styles.cellTop,
              {
                paddingLeft: "2mm",
                width: "100%",
                textAlign: "left",
                marginRight: "1.5mm",
              },
            ]}
          >
            {"Remarks :" + remark}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text
            style={[
              styles.cellBottom,
              {
                paddingLeft: "2mm",
                width: "100%",
                textAlign: "left",
                marginRight: "1.5mm",
              },
            ]}
          >
            {"報關員：" + reporter}
          </Text>
        </View>
        <View style={styles.row} wrap={true}>
          <Text
            style={[
              styles.cellTop,
              {
                fontSize: 9,
                width: "50%",
                paddingLeft: "2mm",
                textAlign: "left",
              },
            ]}
          >
            客戶承認下列條文 :
          </Text>
          <Text
            style={[
              styles.cellTop,
              {
                width: "50%",
                paddingLeft: "2mm",
                textAlign: "left",
                marginRight: "1.5mm",
              },
            ]}
          >
            收貨公司蓋章簽名
          </Text>
        </View>
        <View style={styles.row} wrap={true}>
          <Text
            style={[
              styles.cellMiddle,
              {
                fontSize: 9,
                minHeight: "5mm",
                width: "50%",
                paddingLeft: "2mm",
                textAlign: "left",
              },
            ]}
          >
            1. 茲收到(新和貨櫃有限公司)交來完好封條的貨櫃/貨物
          </Text>
          <Text
            style={[
              styles.cellMiddle,
              {
                minHeight: "5mm",
                width: "50%",
                marginRight: "1.5mm",
              },
            ]}
          ></Text>
        </View>
        <View style={styles.row} wrap={true}>
          <Text
            style={[
              styles.cellMiddle,
              {
                fontSize: 9,
                minHeight: "5mm",
                width: "50%",
                paddingLeft: "2mm",
                textAlign: "left",
              },
            ]}
          >
            2. 我們完全知曉新和貨櫃有限公司為私人之運輸者,
            所有與這公司有關之交易和合同均要按照這公司規則及條例辦理.
          </Text>
          <Text
            style={[
              styles.cellMiddle,
              {
                minHeight: "5mm",
                width: "50%",
                marginRight: "1.5mm",
              },
            ]}
          ></Text>
        </View>
        <View style={styles.row} wrap={true}>
          <Text
            style={[
              styles.cellBottom,
              {
                fontSize: 9,
                minHeight: "5mm",
                width: "50%",
                paddingLeft: "2mm",
                textAlign: "left",
              },
            ]}
          >
            3. 我們已參閱背頁之”致客戶聲明”
          </Text>
          <Text
            style={[
              styles.cellBottom,
              {
                minHeight: "5mm",
                width: "50%",
                marginRight: "1.5mm",
              },
            ]}
          ></Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.back}>
          <Text style={{ marginTop: "1cm", textAlign: "left" }}>致 : 客戶聲明</Text>
          <Text style={{ marginTop: "1cm", textAlign: "left" }}>
            本公司受你委託按照下列標準營業條款和規則安排運輸你的貨櫃/貨物 :
          </Text>
          <Text style={{ marginTop: "1cm", textAlign: "left" }}>
            本公司是按照本港一般慣用服務代你運送貨櫃/貨物。當你的貨櫃/貨物在以下任何情況
            下於運送途中或在我司的保管下受到損失、破爛、本公司一概不負責任。
          </Text>
          <Text style={{ marginTop: "1cm", textAlign: "left" }}>
            1. 由於我顧員/承辦商 代理的過失、疏忽引致你的貨櫃/貨物之損失、破爛。
          </Text>
          <Text style={{ marginTop: "1cm", textAlign: "left" }}>
            2. 由於你的貨櫃/貨物本身和或第三者所引致你的貨櫃/貨物之損失、破爛。
          </Text>
          <Text style={{ marginTop: "1cm", textAlign: "left" }}>
            3. 你的貨櫃/貨物裝上我或其他承運人的車架存放於停車場內或貨倉內過夜我司不負責
            由於水、火、天氣、盜竊和暴動等等 引致你的貨櫃/貨物之損失、破爛。
          </Text>
          <Text style={{ marginTop: "1cm", textAlign: "left" }}>
            4. 本公司不負責因你的貨櫃/貨物運送往來貨櫃碼頭/船公司/貨倉/客戶指定地點因遲
            誤所引致之任何損失、破爛。
          </Text>
          <Text style={{ marginTop: "1cm", textAlign: "left" }}>
            5. 上列所指貨櫃已包括任何貨品存於貨櫃內和貨櫃本身,本公司不負責貨櫃內的貨物短
            少和任何直接、間接由於封條的損失、破爛、超載、貨物的移動之索償。
          </Text>
        </View>
      </Page>
    </Document>
  );
}

// Setting default props for the Header
MyDocument.defaultProps = {
    id: "",
};

// Typechecking props for the Header
MyDocument.propTypes = {
    id: PropTypes.string,
};