import React, { useState, useEffect } from "react";
import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import { GetInvoiceById, AllCompany } from "../../../helper/api";
import f from "assets/fonts/cwTeXKai.ttf";
import fb from "assets/fonts/500sf.ttf";
import officeIcon from "assets/images/sunwo.png";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "Text",
    fontFamily: "cwTeXKai",
    marginLeft: "15mm",
    marginRight: "15mm",
    fontSize: 14,
  },
  section: {
    fontSize: 12,
    fontFamily: "cwTeXKai",
    margin: "2mm",
    padding: "2mm",
    flexGText: 1,
  },
  header: {
    fontSize: 18,
    fontFamily: "cwTeXKai",
    fontWeight: 700,
  },
  cellEmpty: {
    borderTop: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "1.2cm",
  },
  cell: {
    border: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "1.2cm",
  },
  cellTop: {
    borderLeft: "0.5mm",
    borderRight: "0.5mm",
    borderTop: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    paddingTop: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  cellBottom: {
    borderLeft: "0.5mm",
    borderRight: "0.5mm",
    borderBottom: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    paddingBottom: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  cellMiddle: {
    borderLeft: "0.5mm",
    borderRight: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "6mm",
  },
  back: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "cwTeXKai",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "18cm",
    fontFamily: "cwTeXKai",
  },
});

Font.register({
  family: "Noto Serif TC",
  fonts: [{ src: fb }],
});
Font.register({
  family: "cwTeXKai",
  fonts: [{ src: f }],
});
// Create Document Component
export default function MyDocument(props) {
  const [id] = useState(props.id);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([{ date_add: "1999/01/01" }]);

  const [company, setCompany] = useState([]);

  useEffect(() => {
    if (company.length == 0) {
      AllCompany()
        .then((res) => {
          setCompany(res.data.responses);
        })
        .catch((err) => console.log(err));
    }
    if (id !== undefined && company.length > 0) {
      GetInvoiceById(id)
        .then((res) => {
          // console.log( res.data.invoice_data)
          let data = res.data.responses;
          setData(data);
          let Total = 0;
          data.forEach((item) => {
            Total += item.price;
            setTotal(Total);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [company]);

  function ToName(id) {
    for (var i = 0; i < company.length; i++) {
      if (company[i].id == id) {
        return "         " + company[i].c_name;
      }
    }
  }

  function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
  }

  function createEmpty() {
    let a = [];
    for (let x = 0; x < 14 - data.length; x++) {
      a.push(
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellMiddle, { width: "55%" }]}></Text>
          <Text style={[styles.cellMiddle, { width: "20%" }]}></Text>
          <Text style={[styles.cellMiddle, { width: "25%" }]}></Text>
        </View>
      );
    }
    return a;
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image
          style={{
            left: "7mm",
            marginTop: "22mm",
            marginBottom: "2mm",
            position: "absolute",
            width: "10%",
          }}
          src={officeIcon}
        />
        <View style={[styles.row, { marginTop: "20mm" }]}>
          <Text style={[styles.header, { width: "100%", fontSize: 24, textAlign: "center" }]}>
            新和貨櫃有限公司
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.header,
              { width: "100%", fontSize: 18, textAlign: "center", fontFamily: "Noto Serif TC" },
            ]}
          >
            SUN WO CONTAINER COMPANY LTD.
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={{ textAlign: "center", fontSize: 16, width: "100%", marginTop: "1mm" }}>
            元朗福喜街福喜停車場
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              width: "100%",
              fontFamily: "Noto Serif TC",
            }}
          >
            Tel : 24647450 Fax: 24634541
          </Text>
        </View>
        <View style={[styles.row, { marginTop: "5mm", marginBottom: "2mm" }]} wrap={false}>
          <Text style={{ textAlign: "right", width: "35%" }}></Text>
          <Text style={{ textAlign: "center", width: "30%", fontFamily: "Noto Serif TC" }}>
            發票
          </Text>
          <Text
            style={{
              textAlign: "right",
              width: "35%",
              paddingRight: "3mm",
              fontFamily: "Noto Serif TC",
            }}
          >
            No : {pad(id, 5)}
          </Text>
        </View>
        <View style={[styles.row, { marginBottom: "5mm" }]} wrap={false}>
          <Text
            style={{
              textAlign: "center",
              width: "100%",
              textDecoration: "underline",
              fontFamily: "Noto Serif TC",
            }}
          >
            INVOICE
          </Text>
        </View>
        <View style={[styles.row, { marginBottom: "5mm" }]} wrap={false}>
          <Text
            style={{
              textAlign: "right",
              width: "100%",
              paddingRight: "3mm",
              fontFamily: "Noto Serif TC",
            }}
          >
            Date : {data[0].date_add}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[{ fontSize: 20, width: "23mm", textAlign: "left", height: "14mm" }]}>
            {"客戶： "}
          </Text>
          <Text
            style={[
              {
                fontSize: 20,
                textAlign: "left",
                marginRight: "1.5mm",
                height: "14mm",
              },
            ]}
          >
            {ToName(data[0].c_id)}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[{ fontSize: 16, width: "26mm", textAlign: "left", height: "12mm" }]}>
            {"車牌號碼： "}
          </Text>
          <Text
            style={[
              {
                fontSize: 15,
                textAlign: "left",
                marginRight: "1.5mm",
                height: "12mm",
                fontFamily: "Noto Serif TC",
              },
            ]}
          >
            {data[0].car_number}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[{ fontSize: 16, width: "26mm", textAlign: "left", height: "12mm" }]}>
            {"貨櫃編號： "}
          </Text>
          <Text
            style={[
              {
                fontSize: 15,
                textAlign: "left",
                marginRight: "1.5mm",
                height: "12mm",
                fontFamily: "Noto Serif TC",
              },
            ]}
          >
            {data[0].container_number}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[{ fontSize: 16, width: "26mm", textAlign: "left", height: "12mm" }]}>
            {"海關編號： "}
          </Text>
          <Text
            style={[
              {
                fontSize: 15,
                textAlign: "left",
                marginRight: "1.5mm",
                height: "12mm",
                fontFamily: "Noto Serif TC",
              },
            ]}
          >
            {data[0].custom_number}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellTop, { width: "55%", fontFamily: "Noto Serif TC" }]}>貨名</Text>
          <Text style={[styles.cellTop, { width: "20%", fontFamily: "Noto Serif TC" }]}>呎碼</Text>
          <Text style={[styles.cellTop, { width: "25%", fontFamily: "Noto Serif TC" }]}>金額</Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellBottom, { width: "55%", fontFamily: "Noto Serif TC" }]}>
            DESPRIPTION
          </Text>
          <Text style={[styles.cellBottom, { width: "20%", fontFamily: "Noto Serif TC" }]}>
            Size
          </Text>
          <Text style={[styles.cellBottom, { width: "25%", fontFamily: "Noto Serif TC" }]}>
            AMOUNT(HKD)
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellMiddle, { width: "55%" }]}></Text>
          <Text style={[styles.cellMiddle, { width: "20%" }]}></Text>
          <Text style={[styles.cellMiddle, { width: "25%" }]}></Text>
        </View>
        {data.map((item, i) => {
          return (
            <View style={styles.row} wrap={false} key={i}>
              <Text style={[styles.cellMiddle, { width: "55%" }]}>{item.despription}</Text>
              <Text style={[styles.cellMiddle, { width: "20%" }]}>
                {i == 0 ? item.size + "呎" : null}
              </Text>
              <Text style={[styles.cellMiddle, { width: "25%" }]}>
                {numberWithCommas(Number.parseFloat(item.price).toFixed(2))}
              </Text>
            </View>
          );
        })}
        {createEmpty()}
        <View style={[styles.row]} wrap={false}>
          <Text style={[styles.cellEmpty, { width: "55%" }]}></Text>
          <Text style={[styles.cell, { width: "20%", fontFamily: "Noto Serif TC" }]}>
            {"合計\nTOTAL HKD:"}
          </Text>
          <Text
            style={[
              styles.cell,
              { paddingTop: "6mm", alignItems: "center", fontSize: 18, width: "25%" },
            ]}
          >
            {numberWithCommas(Number.parseFloat(total).toFixed(2))}
          </Text>
        </View>
      </Page>
    </Document>
  );
}

// Setting default props for the Header
MyDocument.defaultProps = {
  id: "",
};

// Typechecking props for the Header
MyDocument.propTypes = {
  id: PropTypes.string,
};
