import React, { useState } from "react";
import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import f from "assets/fonts/cwTeXKai.ttf";
import fb from "assets/fonts/500sf.ttf";
import officeIcon from "assets/images/sunwo.png";
import moment from "moment";
import Nzh from "nzh";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "Text",
    fontFamily: "cwTeXKai",
    marginLeft: "15mm",
    marginRight: "15mm",
    paddingTop: "10mm",
    paddingBottom: "10mm",
    fontSize: 14,
  },
  section: {
    fontSize: 12,
    fontFamily: "cwTeXKai",
    margin: "2mm",
    padding: "2mm",
    flexGText: 1,
  },
  header: {
    fontSize: 18,
    fontFamily: "cwTeXKai",
    fontWeight: 700,
  },
  cellEmpty: {
    borderTop: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "1.2cm",
  },
  cell: {
    border: "0mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "1.2cm",
  },
  cellTop: {
    borderTop: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    paddingTop: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  cellBottom: {
    borderBottom: "0.5mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    paddingBottom: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  cellMiddle: {
    border: "0mm",
    marginTop: "-0.5mm",
    marginRight: "-0.5mm",
    padding: "2mm",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    minHeight: "6mm",
  },
  back: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "cwTeXKai",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "18cm",
    fontFamily: "cwTeXKai",
  },
});

Font.register({
  family: "Noto Serif TC",
  fonts: [{ src: fb }],
});
Font.register({
  family: "cwTeXKai",
  fonts: [{ src: f }],
});
// Create Document Component
export default function MyDocument(props) {
  const [all] = useState(props.all);
  const [total] = useState(props.total);
  const [data] = useState(props.data);

  const [company] = useState(props.company);

  console.log(data);
  function ToName(id) {
    for (var i = 0; i < company.length; i++) {
      if (company[i].id == id) {
        return "         " + company[i].c_name;
      }
    }
  }

  function ToChinese(num) {
    var nzh = new Nzh({
      ch: "零壹貳參肆伍陸柒捌玖", // 数字字符
      ch_u: "個拾佰仟萬億兆京", // 数位单位字符，万以下十进制，万以上万进制，个位不能省略
      ch_f: "負", // 负字符
      ch_d: "", // 小数点字符
      m_u: "元角分厘", // 金额单位
      m_t: "港幣", // 金额前缀
      m_z: "正", // 金额无小数后缀
    });
    var number = nzh.toMoney(num);
    return number;
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image
          style={{
            left: "7mm",
            marginTop: "22mm",
            marginBottom: "2mm",
            position: "absolute",
            width: "10%",
          }}
          src={officeIcon}
        />
        <View style={[styles.row, { marginTop: "10mm" }]}>
          <Text style={[styles.header, { width: "100%", fontSize: 24, textAlign: "center" }]}>
            新和貨櫃有限公司
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.header,
              { width: "100%", fontSize: 18, textAlign: "center", fontFamily: "Noto Serif TC" },
            ]}
          >
            SUN WO CONTAINER COMPANY LTD.
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={{ textAlign: "center", fontSize: 16, width: "100%", marginTop: "1mm" }}>
            元朗福喜街福喜停車場
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              width: "100%",
              fontFamily: "Noto Serif TC",
            }}
          >
            Tel : 24647450 Fax: 24634541
          </Text>
        </View>
        <View style={[styles.row, { marginTop: "5mm", marginBottom: "2mm" }]} wrap={false}>
          <Text style={{ textAlign: "center", width: "100%", fontFamily: "Noto Serif TC" }}>
            月結單
          </Text>
        </View>
        <View style={[styles.row, { marginBottom: "5mm" }]} wrap={false}>
          <Text
            style={{
              textAlign: "center",
              width: "100%",
              textDecoration: "underline",
              fontFamily: "Noto Serif TC",
            }}
          >
            STATMENT
          </Text>
        </View>
        <View style={[styles.row, { marginBottom: "5mm" }]} wrap={false}>
          <Text
            style={{
              textAlign: "right",
              width: "100%",
              paddingRight: "3mm",
              fontFamily: "Noto Serif TC",
            }}
          >
            Date : {moment(new Date()).format("yyyy/MM/DD")}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[{ fontSize: 20, width: "23mm", textAlign: "left", height: "14mm" }]}>
            {"客戶： "}
          </Text>
          <Text
            style={[
              {
                fontSize: 20,
                textAlign: "left",
                marginRight: "1.5mm",
                height: "14mm",
              },
            ]}
          >
            {data[0]["c_id"] == 0 ? "全部" : ToName(data[0]["c_id"])}
          </Text>
        </View>
        <View style={styles.row} wrap={false}>
          <Text style={[styles.cellBottom, { fontSize: 18, width: "30%" }]}>發票日期</Text>
          <Text style={[styles.cellBottom, { fontSize: 18, width: "35%" }]}>發票號碼</Text>
          <Text style={[styles.cellBottom, { fontSize: 18, width: "35%" }]}>金額（港幣）</Text>
        </View>
        {data.map((item, i) => {
          return item.despription_number == 1 ? (
            <View style={[styles.row]} wrap={false} key={i}>
              <Text style={[styles.cellMiddle, { width: "30%" }]}>{item.date_add}</Text>
              <Text style={[styles.cellMiddle, { width: "35%" }]}>{item.invoice_id}</Text>
              <Text style={[styles.cellMiddle, { width: "35%" }]}>
                {numberWithCommas(Number.parseFloat(total[item.invoice_id]).toFixed(2))}
              </Text>
            </View>
          ) : null;
        })}
        <View style={[styles.row]} wrap={false}>
          <Text style={[styles.cellTop, { fontSize: 20, width: "100%" }]}>
            總數：{ToChinese(all)} (HKD {numberWithCommas(Number.parseFloat(all).toFixed(2))})
          </Text>
        </View>
        <View style={[styles.row, { marginTop: "15mm" }]} wrap={false}>
          <Text style={[styles.cellTop, { fontSize: 20, width: "100%", textAlign: "left" }]}>
            {"戶口名稱 : 新和貨櫃有限公司\n銀行名稱 : 中國銀行(香港)有限公司\n戶口號碼 : 012-382-00010526"}
          </Text>
        </View>
      </Page>
    </Document>
  );
}

// Setting default props for the Header
MyDocument.defaultProps = {
  data: [],
  company: [],
  all: 0,
  total: [],
};

// Typechecking props for the Header
MyDocument.propTypes = {
  data: PropTypes.array,
  company: PropTypes.array,
  all: PropTypes.number,
  total: PropTypes.array,
};
