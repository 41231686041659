import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { AllCompany } from "../../../helper/api";
import _ from "lodash";
function DataTables() {
  const [data, setData] = useState([]);
  const [company, setCompany] = useState([]);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "公司名稱",
      accessor: "c_name",
    },

    {
      Header: "公司地址",
      accessor: "c_address",
    },

    {
      Header: "聯絡人",
      accessor: "contact",
    },

    {
      Header: "電話",
      accessor: "phone",
    },

    {
      Header: "第二電話",
      accessor: "phone2",
    },

    {
      Header: "Edit",
      accessor: "edit",
      align: "center",
    },
  ];

  function edit(id) {
    return (
        <Link to={"/admin/company/edit/" + id} style={{ float: "right" }}>
          <MDButton color="info">更改</MDButton>
        </Link>
    );
  }

  useEffect(() => {
    if (company.length > 0) {
      var tmp = [];

      company.forEach((item) => {
        item.edit = edit(item.id)
        return tmp.push(item) 
      })

      setData(_.orderBy(tmp, ["id"], ["desc"]));
    }
  }, [company]);

  useEffect(() => {
    if (company.length == 0) {
      AllCompany()
        .then((res) => {
          setCompany(res.data.responses);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
            公司
            </MDTypography>
            <MDTypography variant="button" color="Header">
              Company
            </MDTypography>
            <Link to="/admin/company/add" style={{ float: "right" }}>
              <MDButton color="primary">新增公司</MDButton>
            </Link>
          </MDBox>
          <DataTable table={{ rows: data, columns }} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;
