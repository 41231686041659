import x_spreadsheet from "x-data-spreadsheet";

import { read, utils, writeFile } from "xlsx";

import { stox } from "./stox";

var config = {
  mode: "read", // edit | read
  showToolbar: true,
  showGrid: true,
  showContextmenu: true,
  view: {
    height: () => document.documentElement.clientHeight * 0.9,
  },
  row: {
    len: 10000,
    height: 25,
  },
};

export function Render({ id, book }) {
  document.getElementById(id).innerHTML = null;
  config.view.width = () => document.getElementById(id).offsetWidth;
  const grid = new x_spreadsheet(document.getElementById(id), config);
  grid.loadData(stox(book));
  return grid;
}

export function AddSheetToBook({ book, data, sheetName, skipHeader = false }) {
  const ws = utils.json_to_sheet(data, { skipHeader});
  utils.book_append_sheet(book, ws, sheetName);
}

export async function NewBookFromFile(file) {
  const f = await file.arrayBuffer();
  return read(f);
}

export function NewBook() {
  return utils.book_new();
}

export function SaveXlsx({ book, fileName }) {
  writeFile(book, fileName);
}