import React, { useState, useEffect } from "react";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Print from "./Print";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
// import _ from "lodash";

import { AllCompany, InvoiceGetCompany } from "../../../helper/api";

import moment from "moment";
export default function Edit_Receipt() {
  const [c_id, setC_id] = useState("1");
  const [date_add, setDateAdd] = useState(new Date());

  const [company, setCompany] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [rdy, setRdy] = useState(false);

  const Submit = () => {
    setRdy(false);
    InvoiceGetCompany(c_id.id, moment(date_add).format("yyyy"), moment(date_add).format("MM"))
      .then((res) => {
        let d = res.data.responses;
        setData(d);
        let Total = 0;
        d.forEach((item) => {
          Total += item.price;
        });
        setTotal(Total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (company.length == 0) {
      AllCompany()
        .then((res) => {
          setCompany(res.data.responses);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 403) console.log(err);
        });
    }
  }, [company]);

  useEffect(() => {
    console.log(data);
    if (data.length > 0) setRdy(true);
  }, [total]);

  const checkSubmit = () => {
    return false;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              每月發票
            </MDTypography>
            <MDTypography variant="button" color="Header">
              MONTHLY INVOICE
            </MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  value={c_id}
                  onChange={(_, value) => setC_id(value)}
                  getOptionSelected={(option) => option.id == c_id}
                  options={company}
                  getOptionLabel={(option) => option.c_name}
                  renderInput={(params) => (
                    <FormField
                      size="large"
                      {...params}
                      label="公司"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <MDDatePicker
                  onChange={([date]) => {
                    setDateAdd(date);
                  }}
                  value={date_add}
                  options={{
                    maxDate: new Date(),
                    dateFormat: "Y-m",
                    altInput: true,
                    altFormat: "Y-m",
                    ariaDateFormat: "Y-m",
                  }}
                  input={{ placeholder: "日期" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDButton
                  disabled={checkSubmit()}
                  variant="gradient"
                  color="info"
                  size="large"
                  onClick={() => Submit()}
                >
                  提交
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
        {rdy ? (
          <Print
            data={data}
            company={company}
            total={total}
            year={moment(date_add).format("yyyy")}
            month={moment(date_add).format("MM")}
          />
        ) : null}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
