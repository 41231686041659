import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui core components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import { NewDespription, EditDespription } from "../../../helper/api";

export default function Edit(props) {
  const [despription, setDespription] = useState(props.item.despription);
  const [type, setType] = React.useState(props.item.type || 1);

  const Submit = () => {
    if (props.item.id === 0) {
      NewDespription({
        despription: despription,
        type: type,
      })
        .then((res) => {
          if (res.data.success) props.handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      EditDespription(props.item.id, {
        despription: despription,
        type: type,
      })
        .then((res) => {
          if (res.data.success) props.handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const checkSubmit = () => {
    return false;
  };

  return (
    <MDBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={1} />
        <Grid item xs={12} lg={10}>
          <MDBox p={3}>
            <MDTypography variant="h5">更改貨物</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  size="large"
                  value={despription}
                  label="貨物名稱"
                  placeholder="輸入貨物名稱"
                  onChange={(event) => setDespription(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel id="demo-simple-select-label">類別</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={type}
                  label="類別"
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={1}>收入</MenuItem>
                  <MenuItem value={2}>非收入</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDButton
                  disabled={checkSubmit()}
                  variant="gradient"
                  color="info"
                  size="large"
                  fullWidth
                  style={{ float: "right" }}
                  onClick={() => Submit()}
                >
                  提交
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Setting default props for the Header
Edit.defaultProps = {
  item: {},
};

// Typechecking props for the Header
Edit.propTypes = {
  item: PropTypes.object,
  handleClose: PropTypes.func,
};
